<template>
<div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
        <p class="title">Przypomnij hasło</p>
        <form @submit.prevent="remindPassword">
            <div class="input-group">
                <input class="input" type="text" id="email" placeholder="e-mail" v-model="email" required />
            </div>
            <div class="button-group">
                <button class="button" type="submit">WYŚLIJ</button>
                <button class="button" type="button" @click="closeModal">ANULUJ</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            email: '',
        };
    },
    mounted() {
        window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
    },
    methods: {
        async remindPassword() {
            try {
                await this.$store.dispatch('forgotPassword', this.email);

                Swal.fire({
                    title: 'Sukces!',
                    text: 'Na podany adres e-mail został wysłany link do resetowania hasła.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    this.closeModal();
                });
            } catch (error) {
                Swal.fire({
                    title: 'Błąd!',
                    text: 'Wystąpił problem z wysyłką linku do resetowania hasła. Spróbuj ponownie.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
        },
        handleKeyUp(event) {
            if (event.key === 'Escape') {
                this.closeModal();
            }
        },
        closeModal() {
            this.$emit('close-remind');
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $tertiary-color;
    font-family: 'Roboto-Light', 'sans-serif';
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    width: 70%;
    max-width: 400px;
}

.title {
    text-align: center;
    font-size: 24px;
    color: $primary-color;
    font-family: 'Roboto-Light', 'sans-serif';
}

.input-group {
    margin-bottom: 15px;
    display: flex;
    margin-bottom: 15px;
}

.button-group {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
}

.button {
    cursor: pointer;
    font-family: 'Roboto-Light', 'sans-serif';
    font-size: 16px;
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 10px;
    color: $tertiary-color;
    background-color: $primary-color;
    border: 2px solid transparent;
    transition: all 0.3s ease;
}

.button:hover {
    color: $white;
    background-color: $primary-color;
    border: 2px solid $tertiary-color;
}

.input {
    color: $white;
    font-size: 18px;
    width: 100%;
    padding: 8px;
    background-color: $secondary-color;
    outline: none;
    border: 2px solid transparent;
    transition: all 0.3s ease;
    border-radius: 10px;
    flex: 1;
}

.input:hover,
.input:focus {
    border: 2px solid $primary-color;
}

@media (max-width: 768px) {
    .button-group {
        width: 75%;
    }
}
</style>