<template>
<div class="lump-sum">
    <p class="title">Twój ryczałt za MIESIĄC ROK:</p>
    <p class="lump"><strong>KWOTA PLN brutto</strong></p>
    <p class="information">Prosimy uregulować płatność do DNIA kolejnego miesiąca.</p>
</div>
</template>

<style lang="scss">
.lump-sum {
    margin: 0 auto;
    padding: 20px;
    font-size: 18px;
    color: $white;
    height: 100vh;
}

.title,
.lump,
.information {
    text-align: left;
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
}

.title,
.lump {
    margin-bottom: 20px;
}
</style>