<template>
<div class="authorization-page">
    <div v-if="!isMobile" class="auth-container">
        <div class="auth-section">
            <LoginForm />
        </div>
        <div class="auth-section">
            <RegisterForm @registration-success="handleRegistrationSuccess" />
        </div>

        <div class="auth-overlay" :class="{ 'overlay-left': overlayOnLeft, 'overlay-right': !overlayOnLeft }">
            <div class="overlay-content">
                <h2>{{ overlayOnLeft ? 'Dziękujemy za rejestrację!' : 'Witaj z powrotem!' }}</h2>
                <p>{{ overlayOnLeft ? 'Jesteś już członkiem naszej załogi?' : 'Nie masz jeszcze swojego profilu Cabbie?' }}</p>
                <button @click="toggleOverlay">
                    {{ overlayOnLeft ? 'ZALOGUJ SIĘ' : 'ZAREJESTRUJ SIĘ' }}
                </button>
            </div>
        </div>
    </div>

    <div v-if="isMobile" class="mobile-auth-container" :class="modalSize">
        <div v-if="!showRegister">
            <LoginForm @login-success="handleLoginSuccess" />
        </div>
        <div v-if="showRegister">
            <RegisterForm @registration-success="handleRegistrationSuccess" />
        </div>
        <button class="toggle-registration" @click="toggleRegistration">
            {{ showRegister ? 'ZALOGUJ SIĘ' : 'ZAREJESTRUJ SIĘ' }}
        </button>
    </div>
</div>
</template>

<script>
import LoginForm from './LoginForm.vue';
import RegisterForm from './RegisterForm.vue';

export default {
    components: {
        LoginForm,
        RegisterForm
    },
    data() {
        return {
            overlayOnLeft: false,
            isMobile: window.innerWidth <= 768,
            showRegister: false
        };
    },
    methods: {
        toggleOverlay() {
            this.overlayOnLeft = !this.overlayOnLeft;
        },
        toggleRegistration() {
            this.showRegister = !this.showRegister;
        },
        handleRegistrationSuccess() {
            if (this.isMobile) {
                this.showRegister = false;
            }
            this.overlayOnLeft = false;
        },
        updateIsMobile() {
            this.isMobile = window.innerWidth <= 768;
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateIsMobile);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    },
    computed: {
        currentForm() {
            if (this.showRegister) {
                return RegisterForm;
            } else {
                return LoginForm;
            }
        },
        modalSize() {
            if (this.showRegister) {
                return 'large-modal';
            } else {
                return 'small-modal';
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.authorization-page {
    background-image: url('@/assets/images/Group 97.png');
    background-size: cover;
    background-position: center;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-container {
    position: relative;
    display: flex;
    background-color: $tertiary-color;
    border-radius: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 90%;
    max-width: 800px;
}

.small-modal {
    height: 270px;
}

.large-modal {
    height: 375px;
}

.mobile-auth-container {
    background-color: $tertiary-color;
    padding: 50px 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    width: 70%;
    transition: height 0.3s ease;
}

.auth-section {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
}

.auth-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto-Light', 'sans-serif';
    transition: transform 0.75s cubic-bezier(0.6, 0.05, 0.2, 0.95);
    z-index: 1;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateX(0);
    border-radius: 0 15px 15px 0;
}

.overlay-left {
    transform: translateX(0%);
    border-radius: 0 15px 15px 0;
}

.overlay-right {
    transform: translateX(100%);
    border-radius: 15px 0 0 15px;
}

.overlay-content {
    text-align: center;
    padding: 20px;
}

.overlay-content h2 {
    margin-bottom: 10px;
    font-size: 18px;
}

.overlay-content p {
    margin-bottom: 20px;
    font-size: 14px;
}

.overlay-content button {
    background-color: $tertiary-color;
    color: $white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.overlay-content button:hover {
    background-color: $secondary-color;
}

.toggle-registration {
    margin-top: 20px;
    background-color: transparent;
    color: $primary-color;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.3s ease;
}
</style>