<template>
<div class="access-denied">
    <h1>Brak dostępu</h1>
    <p>Nie masz uprawnień, aby uzyskać dostęp do tej strony.</p>
    <button v-if="isLoggedIn" class="send" @click="goToHome">Wróć</button>
    <button v-if="!isLoggedIn" class="send" @click="goToLogin">Zaloguj się</button>
</div>
</template>

<script>
import store from '@/store';

export default {
  name: 'AccessDeniedPage',
  data() {
    return {
      isLoggedIn: store.getters.isLoggedIn
    }
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'AuthorizationPage' });
    },
    goToHome() {
      this.$router.push({ name: 'Home' });
    }
  }
};
</script>

<style lang="scss" scoped>
.access-denied {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Roboto-Light', 'sans-serif';
    height: 90vh;

    h1 {
        color: $primary-color;
    }

    p {
        color: $white;
        width: 80%;
    }
}

.send {
    background-color: $primary-color;
    color: $white;
    border: 2px solid $primary-color;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Roboto-Light', 'sans-serif';
    font-size: 18px;
    padding: 8px 15px;
    width: 120px;
    margin-top: 30px;
    transition: all 0.3s ease;
    color: $tertiary-color;
}

.send:hover {
    color: $white;
    background-color: $primary-color;
    border: 2px solid $tertiary-color;
}
</style>
