<template>
  <div class="access-denied">
    <h1>404 - Strona nie została znaleziona</h1>
    <p>Przepraszamy, ale strona, której szukasz, nie istnieje.</p>
    <button class="send" @click="goToHome">Powrót do strony głównej</button>
  </div>
</template>

<script>
export default {
  name: 'AccessDeniedPage',
  methods: {
    goToHome() {
      this.$router.push({
        name: 'Home'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.access-denied {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Roboto-Light', 'sans-serif';
  height: 90vh;

  h1 {
    color: $primary-color;
  }

  p {
    color: $white;
    width: 80%;
  }
}

.send {
  background-color: $primary-color;
  color: $white;
  border: 2px solid $primary-color;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Roboto-Light', 'sans-serif';
  font-size: 18px;
  padding: 8px 15px;
  width: 200px;
  margin-top: 30px;
  transition: all 0.3s ease;
  color: $tertiary-color;
}

.send:hover {
  color: $white;
  background-color: $primary-color;
  border: 2px solid $tertiary-color;
}
</style>