<template>
<div class="wallet">
    <div class="billing-cycle">
        <p class="title">Rozliczenie</p>
        <div class="custom-select">
            <div class="selected" :class="{ 'no-border-radius': isDropdownOpen }" @click="toggleDropdown">
                {{ selectedDateText || 'Wybierz termin' }}
                <span class="arrow" :class="{ 'open': isDropdownOpen }">
                    <i class="fas fa-angle-down"></i>
                </span>
            </div>
            <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                <div class="options" v-if="isDropdownOpen">
                    <div class="option" v-for="(date, index) in dates" :key="index" @click="selectDate(index)" @mouseover="hoverIndex = index" @mouseleave="hoverIndex = null" :class="{ hover: hoverIndex === index }">
                        {{ date.range }}
                    </div>
                </div>
            </transition>
        </div>
    </div>

    <div class="app-netto" v-if="selectedEarnings">
        <p class="title">App NETTO</p>
        <div class="earnings-container">
            <div class="pie-chart">
                <canvas ref="earningsCanvas"></canvas>
            </div>
            <div class="earnings-details">
                <p class="total-earnings">Łączne zarobki NETTO - <span>{{ selectedEarnings.total }} PLN</span></p>
                <div class="earnings">
                    <p>
                        <span class="bolt-title">Bolt</span> - <span class="percentage">{{ selectedEarnings.bolt.percentage }}%</span> - <span class="amount">{{ selectedEarnings.bolt.amount }} PLN</span>
                    </p>
                    <p>
                        <span class="freenow-title">FreeNow</span> - <span class="percentage">{{ selectedEarnings.freeNow.percentage }}%</span> - <span class="amount">{{ selectedEarnings.freeNow.amount }} PLN</span>
                    </p>
                    <p>
                        <span class="uber-title">Uber</span> - <span class="percentage">{{ selectedEarnings.uber.percentage }}%</span> - <span class="amount">{{ selectedEarnings.uber.amount }} PLN</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="settlements" v-if="selectedSettlements">
        <p class="title">Rozliczenie</p>
        <div class="settlements-container">
            <div class="pie-chart">
                <canvas ref="settlementsCanvas"></canvas>
            </div>
            <div class="settlements-details">
                <p>
                    <span class="earningapp-title">Zarobek APP</span> - <span class="percentage">{{ selectedSettlements.appEarnings.percentage }}%</span> - <span class="amount">{{ selectedSettlements.appEarnings.amount }} PLN</span>
                </p>
                <p>
                    <span class="cashapp-title">Gotówka APP</span> - <span class="percentage">{{ selectedSettlements.cashEarnings.percentage }}%</span> - <span class="amount">{{ selectedSettlements.cashEarnings.amount }} PLN</span>
                </p>
                <p>
                    <span class="contract-service-title">Obsługa Umowy</span> - <span class="percentage">{{ selectedSettlements.contractService.percentage }}%</span> - <span class="amount">{{ selectedSettlements.contractService.amount }} PLN</span>
                </p>
                <p>
                    <span class="uber-title">Obsługa Portalu</span> - <span class="percentage">{{ selectedSettlements.portalService.percentage }}%</span> - <span class="amount">{{ selectedSettlements.portalService.amount }} PLN</span>
                </p>
                <p>
                    <span class="settlement-title">Rozliczenie</span> - <span class="percentage">{{ selectedSettlements.settlements.percentage }}%</span> - <span class="amount">{{ selectedSettlements.settlements.amount }} PLN</span>
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    Chart,
    registerables
} from 'chart.js';

Chart.register(...registerables);

export default {
    data() {
        return {
            dates: [{
                    range: '12-19.04.2024 r.',
                    earnings: {
                        total: 120,
                        bolt: {
                            percentage: 23,
                            amount: 27.60,
                        },
                        freeNow: {
                            percentage: 34,
                            amount: 40.80,
                        },
                        uber: {
                            percentage: 43,
                            amount: 51.60,
                        },
                    },
                    settlements: {
                        appEarnings: {
                            percentage: 35,
                            amount: 50.00
                        },
                        cashEarnings: {
                            percentage: 25,
                            amount: 25.00
                        },
                        contractService: {
                            percentage: 15,
                            amount: 15.00
                        },
                        portalService: {
                            percentage: 10,
                            amount: 20.00
                        },
                        settlements: {
                            percentage: 15,
                            amount: 20.00
                        },
                    },
                },
                {
                    range: '20-27.04.2024 r.',
                    earnings: {
                        total: 100,
                        bolt: {
                            percentage: 30,
                            amount: 30.00,
                        },
                        freeNow: {
                            percentage: 40,
                            amount: 40.00,
                        },
                        uber: {
                            percentage: 30,
                            amount: 30.00,
                        },
                    },
                    settlements: {
                        appEarnings: {
                            percentage: 25,
                            amount: 30.00
                        },
                        cashEarnings: {
                            percentage: 30,
                            amount: 30.00
                        },
                        contractService: {
                            percentage: 20,
                            amount: 40.00
                        },
                        portalService: {
                            percentage: 25,
                            amount: 40.00
                        },
                        settlements: {
                            percentage: 15,
                            amount: 30.00
                        },
                    },
                },
            ],
            selectedDate: null,
            isDropdownOpen: false,
            hoverIndex: null,
            earningsChart: null,
            settlementsChart: null,
        };
    },
    computed: {
        selectedDateText() {
            return this.selectedDate !== null ? this.dates[this.selectedDate].range : '';
        },
        selectedEarnings() {
            return this.selectedDate !== null ? this.dates[this.selectedDate].earnings : null;
        },
        selectedSettlements() {
            return this.selectedDate !== null ? this.dates[this.selectedDate].settlements : null;
        },
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectDate(index) {
            this.selectedDate = index;
            this.isDropdownOpen = false;
            this.$nextTick(() => {
                this.renderCharts();
            });
        },
        renderCharts() {
            this.renderEarningsChart();
            this.renderSettlementsChart();
        },
        renderEarningsChart() {
            const canvas = this.$refs.earningsCanvas;
            const ctx = canvas.getContext('2d');

            if (this.earningsChart) {
                this.earningsChart.destroy();
            }

            const data = {
                datasets: [{
                    data: [
                        this.selectedEarnings.bolt.amount,
                        this.selectedEarnings.freeNow.amount,
                        this.selectedEarnings.uber.amount,
                    ],
                    backgroundColor: ['#7dcf6f', '#d42430', '#e3e3e3'],
                }],
            };

            this.earningsChart = new Chart(ctx, {
                type: 'pie',
                data: data,
                options: {
                    responsive: true,
                },
            });
        },
        renderSettlementsChart() {
            const canvas = this.$refs.settlementsCanvas;
            const ctx = canvas.getContext('2d');

            if (this.settlementsChart) {
                this.settlementsChart.destroy();
            }

            const data = {
                datasets: [{
                    data: [
                        this.selectedSettlements.appEarnings.amount,
                        this.selectedSettlements.cashEarnings.amount,
                        this.selectedSettlements.contractService.amount,
                        this.selectedSettlements.portalService.amount,
                        this.selectedSettlements.settlements.amount,
                    ],
                    backgroundColor: ['#846EDA', '#25AF75', '#FCB331', '#e3e3e3', '#84B5BC'],
                }],
            };

            this.settlementsChart = new Chart(ctx, {
                type: 'pie',
                data: data,
                options: {
                    responsive: true,
                },
            });
        },
        beforeEnter(el) {
            el.style.opacity = 0;
            el.style.transform = 'translateY(-10px)';
        },
        enter(el, done) {
            el.offsetHeight;
            el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
            el.style.opacity = 1;
            el.style.transform = 'translateY(0)';
            done();
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
            el.style.opacity = 0;
            el.style.transform = 'translateY(-10px)';
            setTimeout(() => {
                done();
            }, 300);
        },
    },
    mounted() {
        if (this.selectedDate !== null) {
            this.renderCharts();
        }
    },
};
</script>

<style lang="scss" scoped>
.wallet {
    margin: 0 auto;
    padding: 20px;
    font-size: 18px;
    color: $white;
}

.title {
    text-align: left;
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
}

.billing-cycle {
    display: flex;
    align-items: center;
    gap: 20px;
}

.custom-select {
    position: relative;
    width: 200px;
}

.selected {
    padding: 10px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    background-color: $secondary-color;
    color: $placeholder-color;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.no-border-radius {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.arrow {
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(180deg);
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 14px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    background-color: $secondary-color;
    border-radius: 0 0 10px 10px;
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;
}

.option {
    padding: 10px;
    cursor: pointer;
    color: $white;

    &:hover {
        background-color: $tr_color;
    }
}

.app-netto,
.settlements {
    margin-top: 20px;
}

.earnings-container,
.settlements-container {
    display: flex;
    align-items: flex-start;
    gap: 50px;
    margin-top: 10px;
}

.settlements-container {
    margin-bottom: 20px;
}

.total-earnings {
    font-family: 'Roboto-Light', sans-serif;
}

.earnings,
.settlements {
    margin-top: 10px;
    font-family: 'Roboto-Extra-Light', sans-serif;
}

.bolt-title {
    color: $bolt-color;
}

.freenow-title {
    color: $freenow-color;
}

.uber-title {
    color: $uber-color;
}

.earningapp-title {
    color: $earnings-app;
}

.cashapp-title {
    color: $cash-app;
}

.contract-service-title {
    color: $primary-color;
}

.settlement-title {
    color: $settlement;
}

.pie-chart {
    width: 175px;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {

    .earnings-container,
    .settlements-container {
        flex-direction: column;
        gap: 0;
        justify-content: center;
        align-items: center;
    }
}
</style>