<template>
<div class="FAQ">
    <p class="title">CZĘSTO ZADAWANE PYTANIA</p>

    <button class="admin-button" v-if="getRole === 'admin'" @click="openManageModal">
        <i class="fas fa-cog"></i> ZARZĄDZAJ
    </button>

    <transition name="modal" @before-enter="beforeEnterOverlay" @enter="enterOverlay" @leave="leaveOverlay">
        <div v-if="showManageModal" class="modal-backdrop" @click.self="closeManageModal">
            <transition name="modal-content" @before-enter="beforeEnterModal" @enter="enterModal" @leave="leaveModal">
                <div class="modal">
                    <ManageFAQ @close="closeManageModal" />
                </div>
            </transition>
        </div>
    </transition>

    <main class="main-faq">
        <div class="faq-section" v-for="(section, sectionIndex) in faqSections" :key="sectionIndex">
            <h3 class="faq-section-title" @click="toggleSection(sectionIndex)">
                <i :class="['fas', activeSection === sectionIndex ? 'fa-chevron-up' : 'fa-chevron-down', 'arrow-icon']"></i>
                {{ section.title }}
            </h3>
            <div v-show="activeSection === sectionIndex" class="faq-answers">
                <div>
                    <div class="faq-question" v-for="question in section.questions" :key="question.id" @click="toggleAnswer(question.id)">
                        <p>
                            <i :class="['fas', activeAnswer === question.id ? 'fa-chevron-up' : 'fa-chevron-down', 'arrow-icon']"></i>
                            {{ question.question }}
                        </p>
                        <div v-show="activeAnswer === question.id" class="faq-answer">
                            <p>{{ question.answer }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="faqSections.length === 0" class="no-content">
            <p>BRAK TREŚCI</p>
        </div>

    </main>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import ManageFAQ from "./ManageFAQ.vue";

export default {
    components: {
        ManageFAQ,
    },
    data() {
        return {
            activeAnswer: null,
            activeSection: null,
            showManageModal: false,
        };
    },
    computed: {
        ...mapGetters(["getRole", "getSections"]),
        faqSections() {
            return this.getSections;
        },
    },
    methods: {
        async fetchSections() {
            await this.$store.dispatch("fetchSections");
        },
        toggleAnswer(answer) {
            this.activeAnswer = this.activeAnswer === answer ? null : answer;
        },
        toggleSection(sectionIndex) {
            this.activeSection = this.activeSection === sectionIndex ? null : sectionIndex;
        },
        openManageModal() {
            this.showManageModal = true;
        },
        closeManageModal() {
            this.showManageModal = false;
        },
        handleKeyDown(event) {
            if (event.key === "Escape") {
                this.closeManageModal();
            }
        },
        beforeEnterOverlay(el) {
            el.style.opacity = 0;
            el.style.visibility = "visible";
        },
        enterOverlay(el, done) {
            el.offsetHeight;
            el.style.transition = "opacity 0.3s ease";
            el.style.opacity = 1;
            done();
        },
        leaveOverlay(el, done) {
            el.style.transition = "opacity 0.3s ease";
            el.style.opacity = 0;
            setTimeout(done, 300);
        },
        beforeEnterModal(el) {
            el.style.transform = "scale(0.7)";
            el.style.opacity = 0;
        },
        enterModal(el, done) {
            el.offsetHeight;
            el.style.transition = "transform 0.3s ease, opacity 0.3s ease";
            el.style.transform = "scale(1)";
            el.style.opacity = 1;
            done();
        },
        leaveModal(el, done) {
            el.style.transition = "transform 0.3s ease, opacity 0.3s ease";
            el.style.transform = "scale(0.7)";
            el.style.opacity = 0;
            setTimeout(done, 300);
        },
    },
    mounted() {
        this.fetchSections();
        window.addEventListener("keydown", this.handleKeyDown);
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.handleKeyDown);
    },
};
</script>

<style lang="scss" scoped>
.FAQ {
    margin-bottom: 50px;
    position: relative;
    height: 100vh;
}

.main-faq {
    height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: $scroll-track;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        border-radius: 10px;
    }
}

.title {
    font-family: "Roboto-Light", sans-serif;
    text-align: center;
    font-size: 36px;
    color: $primary-color;
    margin-top: 100px;
    padding: 80px 0;
}

.faq-section {
    margin-left: 50px;
}

.faq-section-title {
    color: $white;
    font-family: "Roboto-Light", sans-serif;
    cursor: pointer;
}

.arrow-icon {
    color: $primary-color;
}

.faq-question {
    width: 75%;
    cursor: pointer;
    color: $white;
    margin-left: 25px;
    font-family: "Roboto-Light", sans-serif;
}

.faq-answer {
    width: 75%;
    margin-left: 25px;
    font-family: "Roboto-Extra-Light", sans-serif;
    white-space: pre-line;
}

.admin-button {
    font-size: 18px;
    color: $white;
    font-family: "Roboto-Light", sans-serif;
    font-weight: 800;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: none;
    text-transform: uppercase;
    transition: color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.admin-button:hover {
    color: $primary-color;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.modal {
    height: 500px;
    width: 90%;
    max-width: 900px;
    padding: 15px;
}

.no-content {
    text-align: center;
    font-family: 'Roboto-Light', sans-serif;
    font-size: 16px;
    color: $placeholder-color;
}

@media (max-width: 768px) {
    .faq-section {
        margin-left: 20px;
    }
}
</style>