<template>
<div class="invoices">
    <div class="billing-cycle">
        <p class="title">Faktura</p>
        <div class="custom-select">
            <div class="selected" :class="{ 'no-border-radius': isDropdownOpen }" @click="toggleDropdown">
                {{ selectedDateText || 'Wybierz termin' }}
                <span class="arrow" :class="{ 'open': isDropdownOpen }">
                    <i class="fas fa-angle-down"></i>
                </span>
            </div>
            <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                <div class="options" v-if="isDropdownOpen">
                    <div class="option" v-for="(date, index) in dates" :key="index" @click="selectDate(index)" @mouseover="hoverIndex = index" @mouseleave="hoverIndex = null" :class="{ hover: hoverIndex === index }">
                        {{ date.range }}
                    </div>
                </div>
            </transition>
        </div>
    </div>

    <div class="invoice-summary" v-if="selectedInvoices">
        <div class="pie-chart">
            <canvas ref="invoiceCanvas"></canvas>
        </div>
        <div class="invoice-details">
            <p class="total-invoice">Łączne zarobki NETTO - <span>{{ selectedInvoices.total }} PLN</span></p>
            <div class="details">
                <p>
                    <span class="vat-title">Stawka VAT</span> - <span class="percentage">{{ selectedInvoices.VAT.percentage }}%</span> - <span class="amount">{{ selectedInvoices.VAT.amount }} PLN</span>
                </p>
                <p>
                    <span class="netto-title">Kwota Netto</span> - <span class="percentage">{{ selectedInvoices.Netto.percentage }}%</span> - <span class="amount">{{ selectedInvoices.Netto.amount }} PLN</span>
                </p>
            </div>
        </div>
    </div>

    <p class="title">Wgraj fakturę</p>
    <div class="upload-invoice">
        <input type="file" id="file-upload" class="file-upload" @change="handleFileUpload" />
        <label for="file-upload" class="upload-button">Wgraj plik</label>
        <p v-if="uploadedFileName" class="uploaded-file-name">{{ uploadedFileName }}</p>
    </div>

    <div class="invoice-data">
        <p class="title">Opis faktury</p>
        <div class="input-row">
            <input type="text" placeholder="DD-MM-RRRR" class="input-field" />
            <div class="custom-select" @click="toggleVatDropdown">
                <div class="selected" :class="{ 'no-border-radius': isVatDropdownOpen }">
                    {{ selectedVatText || 'Stawka VAT' }}
                    <span class="arrow" :class="{ 'open': isVatDropdownOpen }">
                        <i class="fas fa-angle-down"></i>
                    </span>
                </div>
                <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                    <div class="options" v-if="isVatDropdownOpen">
                        <div class="option" v-for="(vat, index) in vatRates" :key="index" @click="(event) => { selectVat(index); event.stopPropagation(); }" @mouseover="hoverVatIndex = index" @mouseleave="hoverVatIndex = null" :class="{ hover: hoverVatIndex === index }">
                            {{ vat }}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="input-row">
            <input type="text" placeholder="Kwota VAT" class="input-field" />
            <input type="text" placeholder="Kwota NETTO" class="input-field" />
        </div>
        <div class="input-row">
            <input type="text" placeholder="Kwota BRUTTO" class="input-field" />
            <div class="custom-select" @click="toggleAppDropdown">
                <div class="selected" :class="{ 'no-border-radius': isAppDropdownOpen }">
                    {{ selectedAppText || 'Aplikacja' }}
                    <span class="arrow" :class="{ 'open': isAppDropdownOpen }">
                        <i class="fas fa-angle-down"></i>
                    </span>
                </div>
                <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                    <div class="options" v-if="isAppDropdownOpen">
                        <div class="option" v-for="(app, index) in applications" :key="index" @click="(event) => { selectApp(index); event.stopPropagation(); }" @mouseover="hoverAppIndex = index" @mouseleave="hoverAppIndex = null" :class="{ hover: hoverAppIndex === index }">
                            {{ app }}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="upload-button-container">
            <button class="upload-button">Wyślij</button>
        </div>
        <div class="invoices-status">
            <p class="title">Status dodanych Faktur</p>
            <div class="invoice" v-for="(invoice, index) in invoices" :key="index">
                <span class="invoice-number">Faktura{{ index + 1 }}.png</span>
                <span :class="`invoice-status status-${invoice.status}`">{{ invoice.status }}</span>
            </div>
        </div>
    </div>
</div>
</template>

  
<script>
import {
    Chart,
    registerables
} from 'chart.js';

Chart.register(...registerables);

export default {
    data() {
        return {
            dates: [{
                    range: '12-19.04.2024 r.',
                    invoices: {
                        total: 120,
                        VAT: {
                            percentage: 23,
                            amount: 27.60,
                        },
                        Netto: {
                            percentage: 77,
                            amount: 92.40,
                        },
                    },
                },
                {
                    range: '20-27.04.2024 r.',
                    invoices: {
                        total: 100,
                        VAT: {
                            percentage: 23,
                            amount: 23.00,
                        },
                        Netto: {
                            percentage: 77,
                            amount: 70.00,
                        },
                    },
                },
            ],
            selectedDate: null,
            isDropdownOpen: false,
            hoverIndex: null,
            vatRates: ['8%', '23%'],
            applications: ['Bolt', 'Uber', 'FreeNow'],
            selectedVat: null,
            selectedApp: null,
            isVatDropdownOpen: false,
            isAppDropdownOpen: false,
            hoverVatIndex: null,
            hoverAppIndex: null,
            uploadedFileName: '',
            invoices: [{
                    status: 'Weryfikacja',
                },
                {
                    status: 'Zaakceptowana',
                },
                {
                    status: 'Odrzucona',
                },
            ],
            invoiceChart: null,
        };
    },
    computed: {
        selectedDateText() {
            return this.selectedDate !== null ? this.dates[this.selectedDate].range : '';
        },
        selectedInvoices() {
            return this.selectedDate !== null ? this.dates[this.selectedDate].invoices : null;
        },
        selectedVatText() {
            return this.selectedVat !== null ? this.vatRates[this.selectedVat] : '';
        },
        selectedAppText() {
            return this.selectedApp !== null ? this.applications[this.selectedApp] : '';
        },
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            this.uploadedFileName = file ? file.name : '';
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectDate(index) {
            this.selectedDate = index;
            this.isDropdownOpen = false;
            this.$nextTick(() => {
                this.renderInvoiceChart();
            });
        },
        toggleVatDropdown() {
            this.isVatDropdownOpen = !this.isVatDropdownOpen;
        },
        selectVat(index) {
            this.selectedVat = index;
            this.isVatDropdownOpen = false;
        },
        toggleAppDropdown() {
            this.isAppDropdownOpen = !this.isAppDropdownOpen;
        },
        selectApp(index) {
            this.selectedApp = index;
            this.isAppDropdownOpen = false;
        },
        renderInvoiceChart() {
            const canvas = this.$refs.invoiceCanvas;
            const ctx = canvas.getContext('2d');

            if (this.invoiceChart) {
                this.invoiceChart.destroy();
            }

            const data = {
                datasets: [{
                    data: [
                        this.selectedInvoices.VAT.amount,
                        this.selectedInvoices.Netto.amount,
                    ],
                    backgroundColor: ['#669DBC', '#FCB331'],
                }, ],
            };

            this.invoiceChart = new Chart(ctx, {
                type: 'pie',
                data: data,
                options: {
                    responsive: true,
                },
            });
        },
        beforeEnter(el) {
            el.style.opacity = 0;
            el.style.transform = 'translateY(-10px)';
        },
        enter(el, done) {
            el.offsetHeight;
            el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
            el.style.opacity = 1;
            el.style.transform = 'translateY(0)';
            done();
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
            el.style.opacity = 0;
            el.style.transform = 'translateY(-10px)';
            setTimeout(() => {
                done();
            }, 300);
        },
    },
};
</script>

<style lang="scss" scoped>
.invoices {
    margin: 0 auto;
    padding: 20px;
    font-size: 18px;
    color: $white;
}

.title {
    text-align: left;
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
}

.billing-cycle {
    display: flex;
    align-items: center;
    gap: 20px;
}

.custom-select {
    position: relative;
    width: 300px;
    color: $white;
}

.selected {
    padding: 10px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    background-color: $secondary-color;
    color: $placeholder-color;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.no-border-radius {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.arrow {
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(180deg);
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 14px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    background-color: $secondary-color;
    border-radius: 0 0 10px 10px;
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;
}

.option {
    padding: 10px;
    cursor: pointer;
    color: $white;

    &:hover {
        background-color: $tr_color;
    }
}

.upload-invoice {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.file-upload {
    display: none;
}

.upload-button {
    display: inline-block;
    background-color: $primary-color;
    color: $tertiary-color;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    font-family: 'Roboto-Light', sans-serif;
}

.upload-button:hover {
    background-color: darken($primary-color, 10%);
}

.upload-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.uploaded-file-name {
    margin-top: 10px;
    font-size: 14px;
    color: $white;
    font-family: 'Roboto-Extra-Light', sans-serif;
}

.input-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.input-field {
    padding: 10px;
    width: 300px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    border: 2px solid transparent;
    border-radius: 15px;
    box-sizing: border-box;
    background-color: $secondary-color;
    color: $placeholder-color;
    transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    outline: none;
}

.invoices-status {
    margin-top: 20px;
}

.invoice {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: $white;
    font-family: 'Roboto-Light', sans-serif;
    border-bottom: 1px solid $background-color;
    font-size: 14px;
}

.invoice-number {
    margin-right: auto;
}

.invoice-status {
    border-radius: 15px;
    font-weight: bold;
}

.status-Weryfikacja {
    color: $primary-color;
}

.status-Zaakceptowana {
    color: $success-color;
}

.status-Odrzucona {
    color: $warning-color;
}

.total-invoice {
    font-family: 'Roboto-Light', sans-serif;
}

.details {
    margin-top: 10px;
    font-family: 'Roboto-Extra-Light', sans-serif;
}

.invoice-summary {
    display: flex;
    align-items: flex-start;
    gap: 50px;
    margin-top: 10px;
}

.vat-title {
    color: $vat;
}

.netto-title {
    color: $primary-color;
}

.pie-chart {
    width: 175px;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .input-row {
        flex-direction: column;
    }

    .input-field {
        width: 100%;
    }

    .custom-select {
        width: 100%;
    }

    .invoice-summary {
        flex-direction: column;
        gap: 0;
        justify-content: center;
        align-items: center;
    }
}
</style>